import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 33 33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M16.5 8.938a4.812 4.812 0 1 0 0 9.624 4.812 4.812 0 0 0 0-9.625m-2.062 4.812a2.062 2.062 0 1 1 4.124 0 2.062 2.062 0 0 1-4.125 0",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M16.5 2.75c-6.075 0-11 4.925-11 11 0 2.024.703 4.054 1.658 5.897.962 1.854 2.228 3.612 3.468 5.118a47 47 0 0 0 4.857 5.047q.057.051.088.077l.016.015.008.006.01.009.895-1.044-.895 1.044.895.767.895-.767-.895-1.044.895 1.044.01-.009.01-.008.014-.013.409-.364a47.096 47.096 0 0 0 4.536-4.76c1.24-1.506 2.506-3.264 3.468-5.119.955-1.842 1.658-3.872 1.658-5.896 0-6.075-4.925-11-11-11m.543 23.745q-.297.289-.543.521a44.36 44.36 0 0 1-3.751-4c-1.167-1.416-2.306-3.01-3.15-4.635-.849-1.638-1.349-3.218-1.349-4.631a8.25 8.25 0 1 1 16.5 0c0 1.413-.5 2.993-1.35 4.63-.843 1.626-1.982 3.22-3.149 4.637a44 44 0 0 1-3.208 3.478",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }